import React, { useEffect, useMemo } from 'react';
import BaseModal, { ModalProps as BaseModalProps } from 'antd/es/modal';
import './Modal.less';
import cx from 'classnames';
import { Alert, Container, Control, ControlProps, Header, HeaderProps, IconProps } from 'components';
import { isI18nMessage, Message } from 'interfaces';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Translate, useConfig, useTranslate } from 'providers';
import { useRenderContent, WithContentOuterProps } from 'hocs';

type ModalOverWriteProps = {
  title?: Message;
  okText?: Message;
  cancelText?: Message;
  okIcon?: IconProps;
  fullScreen?: boolean;
  fullHeight?: boolean;
  wrapProps?: any;
  scrollY?: boolean;
  withTabs?: boolean;
  narrow?: boolean;
  className?: string;
  error?: Error;
  footer?: React.ReactNode;
  controls?: (React.ReactElement<ControlProps> | { children: React.ReactElement<ControlProps>; className?: string })[];
  withSideBar?: {
    header?: HeaderProps;
    body: React.ReactNode;
  };
} & WithContentOuterProps;

export type ModalProps = Omit<BaseModalProps, keyof ModalOverWriteProps> & ModalOverWriteProps;

export const Modal: React.FC<ModalProps> = (props) => {

  const [renderContent, propsWithoutContent] = useRenderContent(props);

  const {
    error,
    title,
    className,
    fullScreen,
    fullHeight,
    withTabs,
    controls,
    withSideBar,
    narrow,
    okText,
    cancelText,
    ...baseModalProps
  } = propsWithoutContent;

  const translate = useTranslate();

  const titleContent = useMemo(() => {
    return !title
      ? null
      : (
        <>
          <h1>{isI18nMessage(title) ? <Translate message={title}/> : title}</h1>
          <ul className={'nav'}>
            {(controls || []).map((c, idx) => {
              if (React.isValidElement(c)) {
                return <li key={idx}>{c}</li>;
              } else if (c?.children) {
                return <li key={idx} className={c.className}>{c.children}</li>;
              } else {
                return null;
              }
            })}
            <li>
              <Control icon={faTimes} onClick={props.onCancel}/>
            </li>
          </ul>
          {withSideBar?.header && (
            <Header
              {...withSideBar.header}
              className={'side-bar-header'}
            />
          )}
        </>
      );
  }, [title, controls, withSideBar?.header]);

  const classNames = useMemo(() => cx({
    'modal-full-height': fullHeight,
    'modal-full-screen': fullScreen,
    'modal-with-tabs': withTabs,
    'modal-has-controls': controls?.length > 0,
    'modal-withSideBar': !!withSideBar,
  }, props.className), [fullHeight, fullScreen, withTabs, controls?.length, !!withSideBar, props.className]);

  const wrapClassNames = useMemo(() => cx(baseModalProps.wrapClassName, {
    'modal-fullscreen': fullScreen,
    'modal-narrow': narrow,
    'modal-withSideBar': !!withSideBar,
    'modal-standalone': !title && props.onCancel,
    'top-offset': fullScreen,
  }), [fullScreen, narrow, !!withSideBar]);

  const modalProps: BaseModalProps = useMemo(() => ({
    title: titleContent,
    okText: translate(okText),
    cancelText: translate(cancelText),
    mask: !fullScreen,
    className: classNames,
    transitionName: 'modal-transition',
    wrapClassName: wrapClassNames,
  }), [titleContent, okText, cancelText, fullScreen, classNames, wrapClassNames]);

  const { system } = useConfig();

  useEffect(() => {
    const maintenanceNotice = document.getElementById('maintenanceNotice');
    const systemBanner = document.getElementById('systemBanner');
    let o = 0;
    if (maintenanceNotice && !system?.acceptedMaintenanceAt) {
      o += maintenanceNotice.clientHeight;
    }
    if (systemBanner) {
      o += systemBanner.clientHeight;
    }
    document.documentElement.style.setProperty('--banner-height', `${o}px`);
  }, [baseModalProps, system?.acceptedMaintenanceAt]);

  return (
    <BaseModal
      {...baseModalProps}
      {...modalProps}
      closable={false}
      destroyOnClose={true}
    >

      <Container grow shrink horizontal>

        {!title && props.onCancel && (
          <Control className={'modal-standalone-close'} icon={faTimes} onClick={props.onCancel}/>
        )}

        <Container grow shrink>

          <Container grow shrink scrollY={props.scrollY}>
            {renderContent()}
          </Container>

          {error && (
            <Container className={'padding-1'}>
              <Alert showIcon error={error} className={'error'} hideDescription={error.name === 'InputError'}/>
            </Container>
          )}

        </Container>

        {withSideBar && (
          <Container className={'modal-side-bar'}>
            {withSideBar.body}
          </Container>
        )}

      </Container>

    </BaseModal>
  );

};
