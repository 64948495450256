import React, { useEffect } from 'react';
import { create } from 'zustand';
import { createSelectors } from 'utils/helpers.ts';
import { useEnvStore } from '../EnvProvider';
import { EventType, Notification } from 'interfaces/api';
import { useInterval } from 'react-use';

const eventBus = new Comment('event-bus');

type EventsDefinition = {
  [EventType.NewNotification]: Notification;
  [EventType.UpdateNotification]: undefined;
};

function publish<T extends EventType>(
  eventName: T,
  payload?: EventsDefinition[T],
): void {
  const event = payload
    ? new CustomEvent(eventName, { detail: payload })
    : new CustomEvent(eventName);
  eventBus.dispatchEvent(event);
}

function isCustomEvent(event: Event): event is CustomEvent {
  return 'detail' in event;
}

type Unsubscribe = () => void;

export function subscribe<T extends EventType>(
  eventName: T,
  handlerFn: (payload: EventsDefinition[T]) => void,
): Unsubscribe {
  const eventHandler = (event: Event) => {
    if (isCustomEvent(event)) {
      const eventPayload: EventsDefinition[T] = event.detail;
      handlerFn(eventPayload);
    }
  };
  eventBus.addEventListener(eventName, eventHandler);
  return () => {
    eventBus.removeEventListener(eventName, eventHandler);
  };
}

export interface SSEState {
  connection: EventSource;
  setConnection: (connection: EventSource) => void;
  connect: () => void;
  error: boolean;
  setError: (error: boolean) => void;
}

export const SSEStore = create<SSEState>((set, get) => ({
  connection: undefined,
  setConnection: connection => set({ connection }),
  connect: () => {

    if (get().connection) {
      get().connection.close();
    }

    const connection = new EventSource(useEnvStore.getState().BACKEND_URL + '/events?x-version=' + APP_VERSION, { withCredentials: true });

    set({ connection });

    connection.onerror = () => {
      connection.close();
      set({ connection: undefined, error: true });
    };

    connection.onmessage = ({ data: eventData }) => {
      const { type, data } = JSON.parse(eventData);
      publish(type, data);
    };

  },
  error: undefined,
  setError: error => set({ error }),
}));

export const useSSE = createSelectors(SSEStore).use;

export const SSEProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const connection = useSSE.connection();
  const connect = useSSE.connect();

  useInterval(
    () => {
      connect();
    },
    connection ? 60000 : 10000,
  );

  useEffect(() => {
    connect();
  }, []);

  return children;
};

