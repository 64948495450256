import React, { useEffect } from 'react';
import { subscribe, useApi, useApiCall } from 'providers';
import { EventType } from 'interfaces/api';
import { App } from 'antd';
import { Icon } from 'components';
import { getIcon, useNotificationLocation } from 'modules/notifications/utils';
import { useAuthUser } from 'modules/auth/providers';
import { create } from 'zustand';
import { createSelectors } from 'utils/helpers.ts';

export interface NotificationsState {
  unreadCount: number;
  loadList?: () => void;
}

export const NotificationsStore = create<NotificationsState>()(set => ({
  unreadCount: 0,
}));

export const useNotificationsStoreSelectors = createSelectors(NotificationsStore).use;

export const NotificationsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const user = useAuthUser();
  const { notifications: { countNotifications, readNotification } } = useApi();

  const { notification: antdNotification } = App.useApp();

  const count = useApiCall(user ? countNotifications : async () => undefined);

  useEffect(() => {
    NotificationsStore.setState({ unreadCount: count.data || 0 });
  }, [count?.data]);

  const notificationLocation = useNotificationLocation();

  useEffect(() => subscribe(EventType.NewNotification, (notification) => {

    const key = notification.id.toString() || 'key';

    count.reload();

    antdNotification.open({
      key: notification.id.toString(),
      message: notification.title,
      description: notification.body,
      placement: 'topRight',
      icon: <Icon icon={getIcon(notification)}/>,
      className: 'notification-popup',
      onClick: async () => {
        antdNotification.destroy(key);
        notificationLocation(notification);
        await readNotification({ id: notification.id });
        count.reload();
      },
    });

    NotificationsStore.getState().loadList?.();

  }), []);

  useEffect(() => subscribe(EventType.UpdateNotification, () => {
    count.reload();
    NotificationsStore.getState().loadList?.();
  }), []);

  return children;
};
