import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Control, ControlProps, Icon, Input, InputFormat, useConfirmModal } from 'components';
import './EditBarcode.less';
import { faCheckCircle, faPencil, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useSetOrders } from 'modules/orders/providers';
import { useTranslate, useConfig, Translate } from 'providers';
import { Color } from 'interfaces';

type EditBarcodeControlProps = Omit<ControlProps, 'onClick'> & {
  tnr: string;
  onDone?: (tnr: string) => void;
};

const labels = messages.orders.wizard.barcodes;

export const EditBarcodeControl = (props: EditBarcodeControlProps) => {

  const { tnr, onDone } = props;
  const [editedTnr, setEditedTnr] = useState<string>(tnr);
  const [modalInstance, setModalInstance] = useState<any>(undefined);

  const editedTnrRef = useRef(editedTnr);

  const { setOrderProperties } = useSetOrders();

  const { system: { freeBarcodesPolicy: { enabled, regex } } } = useConfig();

  const translate = useTranslate();
  const confirmModal = useConfirmModal();

  const policyOk = useMemo(() => (!enabled || !regex) || !!editedTnr?.match(new RegExp(regex)), [regex, editedTnr]);

  const statusIcon = useMemo(() => policyOk ? { icon: faCheckCircle, color: Color.Green } : { icon: faTimesCircle, color: Color.Red }, [policyOk]);

  const content = useMemo(() => {
    if (enabled && !!regex) {
      return (
        <div>
          <Input
            maxLength={100}
            autoFocus
            defaultValue={tnr}
            format={InputFormat.Alphanumeric}
            onChange={(e) => {
              setEditedTnr(e.target.value);
            }}
            placeholder={translate(labels.edit.placeholder)}
          />
          <p className={'barcode-format-policy'}>
            <span className={'barcode-format-policy-status'}>
              <Icon {...statusIcon}/>&nbsp;
              <Translate message={labels.format.title}/>
            </span>
            <span className={'barcode-format-policy-description'}>
              <Translate message={labels.format.description}/>
            </span>
          </p>
        </div>
      );
    }

    return (
      <div>
        <Input
          maxLength={100}
          autoFocus
          defaultValue={tnr}
          format={InputFormat.Alphanumeric}
          onChange={(e) => {
            setEditedTnr(e.target.value);
          }}
          placeholder={translate(labels.edit.placeholder)}
        />
      </div>
    );
  }, [enabled, regex, policyOk, editedTnr]);

  useEffect(() => {
    editedTnrRef.current = editedTnr;
    (modalInstance as any)?.update?.({ content, okButtonProps: { disabled: !policyOk } });
  }, [editedTnr]);

  const perform = () => {
    const instance = confirmModal({
      content,
      title: translate(labels.edit.title),
      okButtonProps: { disabled: !policyOk },
      onOk: () => {
        setOrderProperties({ tnr: editedTnrRef.current });
        onDone?.(editedTnrRef.current);
      },
    });

    setModalInstance(instance);
  };

  return (
    <Control
      icon={faPencil}
      onClick={perform}
    />
  );
};
